<template>
  <div v-if="initialized" class="one-off-offering-flow">
    <one-off-landing-page v-if="index === 0 && configs" @next="next" @close="close" @back="close" />
    <payment-page v-if="index === 1" @back="back"  @close="close" />
  </div>
</template>

<script>

import OneOffLandingPage
  from '@/pages/responsive-pages/authenticated/navigation-pages/offerings/one-off/OneOffLandingPage.vue';
import PaymentPage from '@/pages/responsive-pages/authenticated/navigation-pages/payment/PaymentPage.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import _ from 'lodash';
import router from '@/router';
import Segment from '@/Segment';

export default {
  name: 'OneOffOfferingFlow',
  components: {
    OneOffLandingPage,
    PaymentPage,
  },
  props: {
    offeringId: {
      type: String,
      required: true,
    },
  },
  async created() {
    await this.init();
    await this.selectOffering({ offeringId: this.offeringId });
    const utmParams = _.pickBy(router.currentRoute.query, (value, key) => (key.startsWith('utm_') && value));
    Segment.trackUserGot('OneOffOfferingFlow', { ...utmParams, offeringId: this.offeringId });
  },
  data() {
    return {
      index: 0,
    };
  },
  computed: {
    ...mapGetters('oneOffOfferings', ['offeringsTypes', 'configs']),
    ...mapState('oneOffOfferings', ['initialized']),
  },
  methods: {
    ...mapActions('oneOffOfferings', ['init']),
    ...mapMutations('oneOffOfferings', ['selectOffering']),
    next() {
      this.index++;
    },
    back() {
      this.index--;
    },
    close() {
      this.$router.push({ path: '/home' });
    },
  },
};

</script>

<style scoped lang="scss">

</style>
