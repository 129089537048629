<template>
  <div v-if="initialized" class="one-off-offering-flow">
    <payment-page @back="close"  @close="close" />
  </div>
</template>

<script>

import PaymentPage from '@/pages/responsive-pages/authenticated/navigation-pages/payment/PaymentPage.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import _ from 'lodash';
import router from '@/router';
import Segment from '@/Segment';

export default {
  name: 'OneOffPaymentFlow',
  components: {
    PaymentPage,
  },
  props: {
    offeringId: {
      type: String,
      required: true,
    },
  },
  async created() {
    await this.init();
    await this.selectOffering({ offeringId: this.offeringId });
    const utmParams = _.pickBy(router.currentRoute.query, (value, key) => (key.startsWith('utm_') && value));
    Segment.trackUserGot('OneOffPaymentFlow', { ...utmParams, offeringId: this.offeringId });
  },
  computed: {
    ...mapGetters('oneOffOfferings', ['offeringsTypes', 'configs']),
    ...mapState('oneOffOfferings', ['initialized']),
  },
  methods: {
    ...mapActions('oneOffOfferings', ['init']),
    ...mapMutations('oneOffOfferings', ['selectOffering']),
    close() {
      this.$router.push({ path: '/home' });
    },
  },
};

</script>

<style scoped lang="scss">

</style>
