<template>
  <loading-popup title="עוד רגע נמשיך..." />
</template>

<script>

import LoadingPopup from '@/base-components/onboarding/LoadingPopup';
import router from '@/router';
import CustomersApi from '../../../../api/CustomersApi';
import Segment from '../../../../Segment';
import DDLogs from '../../../../DDLogs';

const WRONG_2FA_ACCOUNT_ERROR_CODE = 3001;

export default {
  name: 'Complete2FALoginScreen',
  components: { LoadingPopup },
  async created() {
    try {
      const response = await CustomersApi.complete2faLogin();
      if (response.error && response.code === WRONG_2FA_ACCOUNT_ERROR_CODE) {
        await router.push({ path: '/wrong-2fa-account-error' });
      } else {
        await router.push({ path: `/${this.$route.query.redirectTo || 'sr'}` });
      }
    } catch (e) {
      Segment.trackUserGot('Bad2faLoginError');
      DDLogs.error('Error happened during 2fa login completion', e);
    }
  },
};
</script>
