<template>
  <div class="unsupported-banks">
    <alert-page :img="require('@/assets/generic-success.png')"
                :title="`הצוות שלנו על זה! נעדכן אותך כשנתמוך ב${sourceDisplayName}.`">
      <template v-slot:content>
        <div class="content">
          בגלל שכרגע אנחנו לא תומכים בחשבון הבנק העיקרי שלך לא נוכל לבנות לך תזרים.
          <span class="bold">נעדכן אותך בוואטסאפ</span>
           ברגע שנתמוך בו. מקווים שבקרוב נוכל לעזור לך לנצח את החודש 💪
        </div>
      </template>
    </alert-page>
  </div>
</template>

<script>
import Segment from '../../../../Segment';
import AlertPage from '../../../../base-components/AlertPage.vue';

export default {
  name: 'UnsupportedBanksMessage',
  components: {
    AlertPage,
  },
  data() {
    return {
      sourceDisplayName: '',
    };
  },
  created() {
    this.sourceDisplayName = this.$route.query.sourceDisplayName;
    this.sourceName = this.$route.query.sourceName;
  },
  mounted() {
    Segment.trackUserGot('UnsupportedBanksMessagePageEntered', { sourceName: this.sourceName });
  },
};
</script>

<style lang="scss" scoped>
  @import '../../../../scss/mixins';

  .unsupported-banks {
  }
</style>
